import React, { useEffect, useState } from 'react';
import { slide as Menu } from 'react-burger-menu';
import { Link } from 'react-scroll';

import audio_silence from "../audio/silence.mp3";
import audio_ogg from "../audio/zene2.oga";

export default props => {

    useEffect(() => {
        document.addEventListener('scroll', event => handleScroll(event))
    });
    
    const handleScroll  = (event) => {
        setTimeout(() => {
            if(document.querySelector("html").scrollTop < 300){
                document.querySelector("#zoneLink").click();
            }
        }, 4000);
    }
    
    handleScroll();

    function audioButton(){
        if(document.querySelector("#player_audio").paused == false){
            document.querySelector("#player_audio").pause()
            document.querySelector("#audioController").className = "muted";
        }
        else{
            document.querySelector("#player_audio").play()
            document.querySelector("#audioController").className = "play";

        }
    }

  return (
    <Menu>
        <audio autoPlay loop id="player_audio" name="player_audio" >
		    <source src={audio_ogg} type="audio/ogg" />
	    </audio>

        <div onClick={() => audioButton() } className="muted" id="audioController">&nbsp;</div>
        
        <div className="item">
            <Link to="hero" className="menu-item" smooth duration={1000}>
                HOME
            </Link>
        </div>
        <div className="item">
            <Link to="about" className="menu-item" smooth duration={1000}>
                THE ZONE
            </Link>
        </div>
        <div className="item">
            <Link to="facts" className="menu-item" smooth duration={1000}>
                ABOUT HUNGARY
            </Link>
        </div>
        <div className="item">
            <Link to="features" className="menu-item" smooth duration={1000}>
                THE INDUSTRIAL SITE
            </Link>
        </div>
        <div className="item">
            <Link to="ideal" className="menu-item" smooth duration={1000}>
                INDUSTRIES
            </Link>
        </div>
        <div className="item">
            <Link to="partner" className="menu-item" smooth duration={1000}>
                YOUR PARTNER
            </Link>
        </div>
        <div className="item">
            <Link to="contact" className="menu-item" smooth duration={1000}>
                CONTACT
            </Link>
        </div>
    </Menu>
  );
};
